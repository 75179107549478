<template>
	<div class="m-login">
		<div class="m-login-form">
			<div class="m-login-box">
				<div class="login-text">
                    <p>找回密码 <span></span></p>
                </div>
				<el-form ref="form" :rules="rules" :model="form" label-width="0">
					<el-form-item prop="name">
						<el-input v-model="form.name" placeholder="请输入手机号/邮箱">
						</el-input>
					</el-form-item>
                    <el-form-item prop="code">
						<el-row :gutter="10">
							<el-col :span="16">
								<el-input v-model="form.code" placeholder="请输入验证码"></el-input>
							</el-col>
							<el-col :span="8">
								<el-button type="primary" class="m-btn" v-if="codeTime == '获取验证码' || codeTime == '再次获取验证码'" @click="sendCode">{{codeTime}}</el-button>
								<el-button type="primary" class="m-btn" v-else >{{codeTime}}s</el-button>
							</el-col>
						</el-row>
					</el-form-item>
					<el-form-item prop="password"> 
						<el-input type="password" v-model="form.password" placeholder="请输入新密码">
						</el-input>
					</el-form-item>
                    <el-form-item prop="password1"> 
						<el-input type="password" v-model="form.password1" placeholder="请重复密码">
						</el-input>
					</el-form-item>
                    <el-form-item>
						<el-button style="background: #437EFE;border: 0;width: 100%;height: 45px;font-size:18px;" type="primary" @click="onSubmit">确认</el-button>
					</el-form-item>                    
				</el-form>
                <div class="m-login-bottom">
                    <div class="m-login-huiyuan" @click="goTo('/member')">
                        <img src="../assets/images/hysj.png" alt="">
                        <p>会员升级</p>
                    </div>
                    <div class="m-login-tz">
                        <span @click="goTo('/login')">登录</span>
                        <span>|</span>
                        <span @click="goTo('/register')">注册</span>
                    </div>
                </div>
			</div>
            <!-- <div class="web-name">
                <img src="../assets/images/web-left.png" alt="">
                <span>河南海外仓供应链管理服务有限公司</span>
                <img src="../assets/images/web-right.png" alt="">
            </div> -->
		</div>
	</div>
</template>

<script>
	import {forgot,email_send,mobile_send} from "../api/index"	
	export default {
		data() {
			return {
				rules: {                    
					name: [{
							required: true,
							message: '请输入手机号/邮箱',
							trigger: ['blur', 'change']
						}
					],
                    code: [{
						required: true,
						message: '请输入验证码',
						trigger: ['blur', 'change']
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: ['blur', 'change']
					}],
                    password1: [{
						required: true,
						message: '请输入密码',
						trigger: ['blur', 'change']
					}],
				},
				form: {
					name:'',
                    code: '',
					password:'',
                    password1:''
				},
                codeTime:'获取验证码'
			}
		},
		methods: {
            goTo(url) {
                this.$router.replace({ 
                    path: url ,
                });
            },
            //发送验证码  2671969600@qq.com
			sendCode(){
				if((/^1[3456789]\d{9}$/.test(this.form.name)) || (/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(this.form.name))){
					
					if ((/^1[3456789]\d{9}$/.test(this.form.name))) {
						this.phoneCode();
						// console.log('手机号');
					}else if((/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(this.form.name))){
						this.emailCode();
						// console.log('邮箱');
					}
				}else{
					this.$message.error('请输入正确的邮箱或者手机号')
					return false;
				}
				
			},
			//发送手机号验证码
			async phoneCode(){
				if(!(/^1[3456789]\d{9}$/.test(this.form.name))){
					this.$message.error('手机号错误')
					return false;
				}
				let res = await mobile_send({mobile:this.form.name})
				// console.log(res);
				if(res.code == 200){
					this.$message.success(res.message)
					this.codeTime = 60
					let _time = setInterval(()=>{
						if(this.codeTime <= 0){
							this.codeTime = '再次获取验证码'
							clearInterval(_time)
							return false
						}
						this.codeTime -= 1
					},1000)
				}else{
					this.$message.error(res.message)
				}
			},

			//发送邮箱激活码
			async emailCode(){
				if(!(/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(this.form.name))){
					this.$message.error('邮箱错误')
					return false;
				}
				let res = await email_send({email:this.form.name})
				// console.log(res);
				if(res.code == 200){
					this.$message.success(res.message)
					this.codeTime = 60
					let _time = setInterval(()=>{
						if(this.codeTime <= 0){
							this.codeTime = '再次获取验证码'
							clearInterval(_time)
							return false
						}
						this.codeTime -= 1
					},1000)
				}else{
					this.$message.error(res.message)
				}
			},

			onSubmit() {
				this.$refs.form.validate(async (valid)=>{
					if(valid){
						if (this.form.password!==this.form.password1) {
							this.$message.error('两次密码输入不一致')
							return false;
						}
						let res = await forgot(this.form)
						// console.log(res);
						if(res.code == 200){
							this.$message.success(res.message)
							setTimeout(() => {
								this.$router.replace({path:'/login',});
							}, 500);
						}else{
							this.$message.error(res.message)
						}
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
/deep/ .el-input__inner{
    border: 1px solid #A0A0A0;
    height: 45px;
    // line-height: 50px;
    font-size: 15px;
}
	.m-login {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: url(../assets/images/login-bg.png) no-repeat;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.m-login-form {
			// display: flex;
			position: relative;
            margin-right: 18%;            
			.m-login-box {
				display: flex;
				flex-direction: column;
				width: 350px;
				margin: 0px auto;
                background:#fff;
                border-radius: 10px;
                padding:40px 58px;
                .m-btn {
					padding: 0;
                    width: 100%;
                    height: 45px;
                    background: #fff;
                    font-size: 14px;
                    color: #676767;
                    border: 1px solid #A0A0A0;
				}
                .login-text{
                    font-size: 22px;
                    border-bottom: 1px solid #A0A0A0;
                    margin-bottom: 35px;
                    p{
                        color: #6982FE;
                        padding-bottom: 30px;
                        position: relative;
                        text-align: center;
                        span{
                            width: 50%;
                            height: 3px;
                            background: #6982FE;
                            position: absolute;
                            bottom: -1px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
                .m-login-bottom{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .m-login-huiyuan{
                        display: flex;
                        align-items: center;
                        color: #999999;
                        font-size: 16px;       
                        cursor: pointer;                 
                        img{width: 20px;margin-right: 5px;}
                    }
                    .m-login-tz{
                        color: #999999;font-size: 16px;cursor: pointer;
                        span:nth-child(2){padding: 0 10px;}
                    }
                }
			}

            .web-name{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 25px;
                color: #fff;
                span{margin: 0 5px;}        
                img{width: 90px;}        
            }
		}
	}
</style>
